<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<!-- eslint-disable @angular-eslint/template/no-any -->
@if (eventType(); as eventType) {
  @if (jobOfferForm) {
    <form class="job-form" [formGroup]="jobOfferForm" (ngSubmit)="saveJobOffer()" #form="ngForm">
      <header class="header">
        <h2 class="header__page-title">{{ pageTitle() }}</h2>
        <div class="form-controls">
          @if (isEditable()) {
            <ul class="form-controls__btn ul-clean">
              @if (readyToSubmit() === false || jobOfferForm.dirty) {
                <li class="form-controls__btn__submit">
                  <button class="btn-clean btn-emphasis-white" type="submit">
                    <mat-icon
                      class="btn-emphasis-white__icon--left"
                      matPrefix
                      svgIcon="save"
                      aria-hidden="true"
                      focusable="false"
                    />Sauvegarder
                  </button>
                </li>
              }
              @if (readyToSubmit() && jobOfferForm.pristine) {
                <li class="form-controls__btn__submit">
                  <button class="btn-clean btn-emphasis-primary" (click)="sendForValidation(true)" type="button">
                    Envoyer pour validation
                  </button>
                </li>
              }
            </ul>
          }
          @if (!isJobEditionAllowed && jobOfferStatus() === OpsJobStatusEnum.STATUS_DRAFT) {
            <p class="form-controls__message form-controls__message--info">
              <mat-icon class="form-controls__message__icon form-controls__message__icon--info" svgIcon="info" />La
              modification pour un évènement passé n'est pas possible
            </p>
          }
        </div>
      </header>
      <div class="job-form__content">
        <div class="job-form__content--columns">
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Intitulé du poste</mat-label>
            <input type="text" formControlName="name" matInput />
            <mat-error>L&apos;intitulé du poste est requis</mat-error>
          </mat-form-field>
          <mat-form-field class="input-icon-right input-simple" appearance="outline">
            <input
              [matDatepicker]="picker"
              (click)="picker.open()"
              autocomplete="off"
              matInput
              formControlName="startDate"
            />
            <mat-label>Date de début</mat-label>
            <mat-datepicker-toggle [for]="picker" matSuffix />
            <mat-datepicker #picker />
            <mat-error>Veuillez indiquer la date de début</mat-error>
          </mat-form-field>
          <wzb-place-picker
            [allowMultipleSelection]="true"
            [required]="true"
            [countries]="['fr', 'yt']"
            [language]="$any(undefined)"
            formControlName="locations"
            locationType="city"
          />
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Type de contrat</mat-label>
            <mat-select formControlName="contractIds" multiple>
              @for (contractType of contractTypes(); track contractType) {
                <mat-option [value]="contractType.id">
                  {{ contractType.title }}
                </mat-option>
              }
            </mat-select>
            <mat-error>Veuillez indiquer le type de contrat</mat-error>
          </mat-form-field>
          @if (displayContractDuration) {
            <mat-form-field class="input-simple" appearance="outline">
              <mat-label>Durée du contrat</mat-label>
              <mat-select formControlName="contractDuration">
                @for (contractDuration of contractDurationsLabelMapping; track contractDuration) {
                  <mat-option [value]="contractDuration.key">
                    {{ contractDuration.label }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Veuillez indiquer la durée du contrat</mat-error>
            </mat-form-field>
          }
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Temps de travail</mat-label>
            <mat-select formControlName="effectiveDuration">
              @for (effectiveDuration of effectiveDurationsLabelsMapping; track effectiveDuration) {
                <mat-option [value]="effectiveDuration.key">
                  {{ effectiveDuration.label }}
                </mat-option>
              }
            </mat-select>
            <mat-error>Veuillez indiquer le temps de travail prévu pour ce poste</mat-error>
          </mat-form-field>
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Nombre d&apos;heures par semaine</mat-label>
            <input autocomplete="off" formControlName="effectiveHoursPerWeek" type="number" min="0" matInput />
            <span matSuffix>&nbsp;h</span>
            <mat-error>Veuillez indiquer le nombre d'heures par semaine</mat-error>
          </mat-form-field>
        </div>
        <div class="job-form__content--ckeditor">
          <p
            class="job-form__content--ckeditor__label"
            [class.job-form__content--ckeditor__label--disabled]="jobOfferForm.disabled"
          >
            Description
          </p>
          <ckeditor
            class="wzb-ckeditor"
            [editor]="Editor"
            [config]="missionsCkEditorConfig"
            [class.wzb-ckeditor--error]="
              jobOfferForm.controls.missions.errors?.required &&
              (form.submitted || jobOfferForm.controls.missions.touched)
            "
            [disabled]="jobOfferForm.disabled"
            (change)="onDescriptionChange($event)"
            formControlName="missions"
          />
          @if (
            jobOfferForm.controls.missions.errors?.required &&
            (form.submitted || jobOfferForm.controls.missions.touched)
          ) {
            <mat-error>Veuillez indiquer une description</mat-error>
          }
        </div>
        <div class="job-form__content--slider-group">
          <p
            class="job-form__content--slider-group__label"
            [class.job-form__content--slider-group__label--disabled]="jobOfferForm.disabled"
          >
            Fourchette de salaire brut annuel
          </p>
          <ngx-slider
            class="remuneration__slider"
            [class.ngx-slider--disabled-untouched]="jobOfferForm.disabled || (!form.submitted && salaryRangeHasErrors)"
            [class.ngx-slider--error]="form.submitted && salaryRangeHasErrors"
            [options]="sliderOptions"
            formControlName="salaryRange"
          />
          <p class="job-form__content--slider-group__text">
            La fourchette de salaire est à titre indicative et permet de réaliser la sélection des candidats
          </p>
          @if (form.submitted && salaryRangeHasErrors) {
            <mat-error>Veuillez indiquer une fourchette de salaire pour ce poste</mat-error>
          }
        </div>
        <mat-form-field class="textarea" appearance="outline">
          <mat-label>Avantages</mat-label>
          <textarea
            [cdkAutosizeMinRows]="textAreaMinRows$ | async"
            matInput
            formControlName="goodPoints"
            cdkTextareaAutosize
            placeholder="Indiquez ici les primes, salaire mensuel, critères, horaires etc."
          ></textarea>
          <mat-error>Veuillez indiquer les avantages de ce poste</mat-error>
        </mat-form-field>
        <div class="job-form__content--columns">
          @if (!isJobDating) {
            <mat-form-field class="input-simple" appearance="outline">
              <mat-label>Diplôme minimum requis</mat-label>
              <mat-select formControlName="degreeId">
                @for (degree of degrees(); track degree) {
                  <mat-option [value]="degree.id">
                    {{ degree.title }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Veuillez indiquer le diplôme minimum requis pour le poste</mat-error>
            </mat-form-field>
            <mat-form-field class="input-simple" appearance="outline">
              <mat-label>Expérience</mat-label>
              <mat-select formControlName="experience">
                @for (experienceLevel of experienceLevelsLabelsMapping; track experienceLevel) {
                  <mat-option [value]="experienceLevel.key">
                    {{ experienceLevel.label }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>Veuillez indiquer l'expérience minimale requise pour le poste</mat-error>
            </mat-form-field>
          }
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Permis de conduire requis</mat-label>
            <mat-select formControlName="driversLicenceRequired">
              <mat-option [value]="true">Oui</mat-option>
              <mat-option [value]="false">Non</mat-option>
            </mat-select>
            <mat-error>Veuillez indiquer si le permis de conduire est obligatoire pour le poste</mat-error>
          </mat-form-field>
          <app-languages-picker [disabled]="jobOfferForm.disabled" formControlName="languages" />
        </div>
        <app-software-skills-picker [disabled]="jobOfferForm.disabled" formControlName="softwareSkills" />
        <div class="job-form__content--columns">
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Nombre de postes à pourvoir</mat-label>
            <input min="1" type="number" autocomplete="off" formControlName="openPositions" matInput />
            <mat-error>Veuillez indiquer le nombre de postes à pourvoir</mat-error>
          </mat-form-field>
          <mat-form-field class="input-simple" appearance="outline">
            <mat-label>Domaine du poste</mat-label>
            <mat-select formControlName="domain">
              @for (group of domainsList; track group) {
                <mat-optgroup [label]="group.label">
                  @for (domain of group.domains; track domain) {
                    <mat-option [value]="domain.key">
                      {{ domain.label }}
                    </mat-option>
                  }
                </mat-optgroup>
              }
            </mat-select>
            <mat-error>Veuillez indiquer le domaine du poste</mat-error>
          </mat-form-field>
        </div>
        <app-qualities-picker [disabled]="jobOfferForm.disabled" formControlName="qualities" />
        <p class="job-form__content__info">
          Dans l&apos;objectif de vous apporter un sourcing de qualité, notre équipe est amenée à diffuser des offres
          d&apos;emploi en intégrant le nom et le secteur d&apos;activité de votre société.
        </p>
        <div class="job-form__content__final-btn">
          @if (isEditable() && jobOfferForm.valid) {
            <ul class="ul-clean">
              @if (readyToSubmit() === false || jobOfferForm.dirty) {
                <li class="form-controls__btn__submit">
                  <button class="btn-clean btn-emphasis-white" type="submit">
                    <mat-icon
                      class="btn-emphasis-white__icon--left"
                      matPrefix
                      svgIcon="save"
                      aria-hidden="true"
                      focusable="false"
                    />Sauvegarder
                  </button>
                </li>
              }
              @if (readyToSubmit() && jobOfferForm.pristine) {
                <li class="form-controls__btn__submit">
                  <button class="btn-clean btn-emphasis-primary" (click)="sendForValidation(true)" type="button">
                    Envoyer pour validation
                  </button>
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </form>
  }
}
